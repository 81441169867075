import React from 'react';

const blogs = [
  {
    title: 'The Changing Landscape of the Construction Industry in India',
    description: 'The construction industry in India is undergoing a significant transformation, driven by various factors such as technological advancements, regulatory reforms.',
    image: 'https://miro.medium.com/v2/resize:fit:720/format:webp/0*E_7yXehqlydOPYpH', 
    link: 'https://blog.supersite.app/the-changing-landscape-of-the-construction-industry-in-india-9a617c826c1a'
  },
  {
    title: 'The Limitations of Traditional Project Management in Construction Industry',
    description: 'In today’s fast-paced and technology-driven world, traditional project management methods are facing significant challenges.',
    image: 'https://miro.medium.com/v2/resize:fit:720/format:webp/0*ljcUQ8BlKYL15OWd',
    link: 'https://blog.supersite.app/the-limitations-of-traditional-project-management-in-construction-industry-6fec2df3b44'
  },
  {
    title: 'Materials management in construction',
    description: 'The construction industry, including the real estate sector, is one of the most challenging industries to operate in, with a number of complexities that must be managed to ensure a project’s success.',
    image: 'https://miro.medium.com/v2/resize:fit:720/format:webp/0*kOv1sG13OferFvqh',
    link: 'https://blog.supersite.app/materials-management-in-construction-6649f8846d90'
  }
];

const Blog = () => {
  return (
    <div id="blog" className="py-20">
      <div className="max-w-7xl mx-auto px-6 md:px-12 xl:px-6">
        <div className="mb-12 text-center">
          <h2 className="text-3xl font-bold md:text-4xl dark:text-white">Latest Insights from Supersite</h2>
          <p className="mt-4 lg:mx-auto lg:w-8/12 text-gray-600 dark:text-gray-400">
            Stay updated with the latest tips, trends, and insights on construction management and industry innovations.
          </p>
        </div>
        <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3">
          {blogs.map((blog, index) => (
            <a
              key={index}
              href={blog.link}
              className="group block p-6 rounded-3xl bg-white border border-gray-200 dark:bg-gray-800 dark:border-gray-700 shadow-lg dark:shadow-none transition-transform duration-300 hover:scale-105"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="relative overflow-hidden rounded-xl">
                <img
                  src={blog.image}
                  alt={blog.title}
                  loading="lazy"
                  width="1000"
                  height="667"
                  className="h-64 w-full object-cover transition-transform duration-300 group-hover:scale-110"
                />
              </div>
              <div className="mt-6">
                <h3 className="text-xl font-semibold dark:text-white">
                  {blog.title}
                </h3>
                <p className="mt-4 text-gray-600 dark:text-gray-400">
                  {blog.description}
                </p>
                <p className="mt-4 inline-block text-indigo-600 dark:text-blue-400 group-hover:underline">
                  Read more
                </p>
              </div>
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Blog;
