import React from 'react';
import { FaChartLine, FaRegClock, FaDollarSign, FaTools, FaHandsHelping, FaShieldAlt } from 'react-icons/fa';

const Benefits = () => {
  return (
    <div id="benefits" className="py-20">
      <div className="max-w-7xl mx-auto px-6 md:px-12 xl:px-6">
        <div className="mb-16 text-center">
          <h2 className="text-2xl font-bold text-gray-800 dark:text-white md:text-4xl">
            Why Choose Supersite?
          </h2>
          <p className="mt-4 text-gray-600 dark:text-gray-400">
            Discover the key benefits of using Supersite for your construction management needs.
          </p>
        </div>
        <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3">
          <div className="relative p-8 border border-gray-100 dark:border-gray-700 rounded-3xl bg-gradient-to-br from-indigo-50 via-purple-50 to-pink-50 dark:from-gray-700 dark:via-gray-800 dark:to-gray-900 text-gray-600 dark:text-gray-300 shadow-lg transition-transform duration-300 ease-in-out transform hover:scale-105 hover:shadow-2xl hover:border-gray-200 dark:hover:border-gray-600">
            <FaChartLine className="text-indigo-600 w-12 h-12 mb-4" />
            <h3 className="text-lg font-semibold text-gray-800 dark:text-white">Increased Efficiency</h3>
            <p className="mt-4 text-sm">
              Streamline your construction processes with our intuitive tools, reducing manual tasks and boosting overall productivity.
            </p>
          </div>
          <div className="relative p-8 border border-gray-100 dark:border-gray-700 rounded-3xl bg-gradient-to-br from-green-50 via-teal-50 to-cyan-50 dark:from-gray-700 dark:via-gray-800 dark:to-gray-900 text-gray-600 dark:text-gray-300 shadow-lg transition-transform duration-300 ease-in-out transform hover:scale-105 hover:shadow-2xl hover:border-gray-200 dark:hover:border-gray-600">
            <FaRegClock className="text-green-600 w-12 h-12 mb-4" />
            <h3 className="text-lg font-semibold text-gray-800 dark:text-white">Time Savings</h3>
            <p className="mt-4 text-sm">
              Reduce project timelines with automated tracking and real-time updates, keeping you on schedule and within budget.
            </p>
          </div>
          <div className="relative p-8 border border-gray-100 dark:border-gray-700 rounded-3xl bg-gradient-to-br from-yellow-50 via-orange-50 to-red-50 dark:from-gray-700 dark:via-gray-800 dark:to-gray-900 text-gray-600 dark:text-gray-300 shadow-lg transition-transform duration-300 ease-in-out transform hover:scale-105 hover:shadow-2xl hover:border-gray-200 dark:hover:border-gray-600">
            <FaDollarSign className="text-yellow-600 w-12 h-12 mb-4" />
            <h3 className="text-lg font-semibold text-gray-800 dark:text-white">Cost Control</h3>
            <p className="mt-4 text-sm">
              Keep track of expenses and manage budgets more effectively with our comprehensive financial management features.
            </p>
          </div>
          <div className="relative p-8 border border-gray-100 dark:border-gray-700 rounded-3xl bg-gradient-to-br from-blue-50 via-teal-50 to-cyan-50 dark:from-gray-700 dark:via-gray-800 dark:to-gray-900 text-gray-600 dark:text-gray-300 shadow-lg transition-transform duration-300 ease-in-out transform hover:scale-105 hover:shadow-2xl hover:border-gray-200 dark:hover:border-gray-600">
            <FaTools className="text-blue-600 w-12 h-12 mb-4" />
            <h3 className="text-lg font-semibold text-gray-800 dark:text-white">Robust Tools</h3>
            <p className="mt-4 text-sm">
              Utilize a wide range of features designed to handle every aspect of construction management with ease and precision.
            </p>
          </div>
          <div className="relative p-8 border border-gray-100 dark:border-gray-700 rounded-3xl bg-gradient-to-br from-teal-50 via-cyan-50 to-blue-50 dark:from-gray-700 dark:via-gray-800 dark:to-gray-900 text-gray-600 dark:text-gray-300 shadow-lg transition-transform duration-300 ease-in-out transform hover:scale-105 hover:shadow-2xl hover:border-gray-200 dark:hover:border-gray-600">
            <FaHandsHelping className="text-teal-600 w-12 h-12 mb-4" />
            <h3 className="text-lg font-semibold text-gray-800 dark:text-white">Enhanced Collaboration</h3>
            <p className="mt-4 text-sm">
              Improve communication and teamwork with our collaborative features, ensuring all stakeholders are on the same page.
            </p>
          </div>
          <div className="relative p-8 border border-gray-100 dark:border-gray-700 rounded-3xl bg-gradient-to-br from-purple-50 via-pink-50 to-red-50 dark:from-gray-700 dark:via-gray-800 dark:to-gray-900 text-gray-600 dark:text-gray-300 shadow-lg transition-transform duration-300 ease-in-out transform hover:scale-105 hover:shadow-2xl hover:border-gray-200 dark:hover:border-gray-600">
            <FaShieldAlt className="text-purple-600 w-12 h-12 mb-4" />
            <h3 className="text-lg font-semibold text-gray-800 dark:text-white">Increased Security</h3>
            <p className="mt-4 text-sm">
              Protect your data with our advanced security measures, ensuring that your project information is safe and secure.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Benefits;
