import React from 'react';
import { FaTasks, FaChartLine, FaTools, FaFileInvoice, FaBuilding, FaUsersCog } from 'react-icons/fa';

const Features = () => {
  return (
    <div id="features" className="py-20">
      <div className="max-w-7xl mx-auto px-6 md:px-12 xl:px-6">
        <div className="md:w-2/3 lg:w-1/2 text-center mx-auto">
          <h2 className="text-2xl font-bold text-gray-900 dark:text-white md:text-4xl">
            Simplify Every Aspect of Your Construction Projects
          </h2>
          <p className="mt-4 text-gray-700 dark:text-gray-300">
            Supersite empowers builders and project managers with tools to manage tasks, track progress, and ensure seamless communication across teams. Explore the features designed to make your projects run smoother.
          </p>
        </div>
        <div className="mt-16 grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
          {[
            { icon: <FaTasks className="text-indigo-600 w-8 h-8 mb-4" />, title: "Task Management", description: "Organize tasks, assign responsibilities, and monitor progress with ease.", gradient: "from-indigo-50 via-purple-50 to-pink-50" },
            { icon: <FaChartLine className="text-green-600 w-8 h-8 mb-4" />, title: "Daily Progress Tracking", description: "Keep track of daily progress across all project phases and ensure timely delivery.", gradient: "from-green-50 via-teal-50 to-cyan-50" },
            { icon: <FaTools className="text-yellow-600 w-8 h-8 mb-4" />, title: "Material Management", description: "Efficiently manage materials, track inventory, and reduce wastage.", gradient: "from-yellow-50 via-orange-50 to-red-50" },
            { icon: <FaFileInvoice className="text-blue-600 w-8 h-8 mb-4" />, title: "Financial Tracking", description: "Monitor budgets, track expenses, and manage invoices seamlessly.", gradient: "from-blue-50 via-teal-50 to-cyan-50" },
            { icon: <FaBuilding className="text-teal-600 w-8 h-8 mb-4" />, title: "Site Management", description: "Get a comprehensive view of site activities, including issues, RFIs, and more.", gradient: "from-teal-50 via-cyan-50 to-blue-50" },
            { icon: <FaUsersCog className="text-purple-600 w-8 h-8 mb-4" />, title: "Team Collaboration", description: "Enhance team collaboration with real-time communication and coordination tools.", gradient: "from-purple-50 via-pink-50 to-red-50" },
          ].map((feature, index) => (
            <div
              key={index}
              className={`relative p-8 border border-gray-100 dark:border-gray-700 rounded-3xl bg-gradient-to-br ${feature.gradient} dark:from-gray-700 dark:via-gray-800 dark:to-gray-900 text-gray-600 dark:text-gray-300 transition-transform duration-300 ease-in-out transform hover:scale-105 hover:shadow-lg hover:border-gray-200 dark:hover:border-gray-600`}
            >
              {feature.icon}
              <h3 className="text-lg font-semibold text-gray-800 dark:text-white">{feature.title}</h3>
              <p className="mt-4 text-sm">{feature.description}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Features;
