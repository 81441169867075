import React from 'react';
import HeroSection from '../components/HeroSection';
import Features from '../components/Features';
import CallToAction from '../components/CallToAction';
import Testimonials from '../components/Testimonials';
import Benefits from '../components/Benefits';
import AppFooter from '../components/AppFooter';
import Pricing from '../components/Pricing';
import Blog from '../components/Blog';
import Showcase from '../components/Showcase';

const HomePage = () => {
  return (
    <>
      <HeroSection />
      <Features />
      <Benefits />
      <Showcase />
      <Pricing />
      <Testimonials />
      <Blog />
      <CallToAction />
      <AppFooter />
    </>
  );
};

export default HomePage;
